import React, { useContext } from "react";
import { GridIcon } from "../Icons";
import { Context } from "../context"
import styled from "styled-components";
import PrimaryButton from "../Button/PrimaryButton";

const AddCartComparison = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const AddToComparison = ({ product }) => {
  const {
    comparisonData,
    setComparisonData,
    setLastlyAddedProductCompare
  } = useContext(Context);

  const addCompare = () => {
    if (!comparisonData || !comparisonData.length) {
      setComparisonData([product]);
      return;
    }

    const newData = [...comparisonData];
    let found = comparisonData.findIndex((item) => item.id === product.id);
    if (found >= 0) {
      newData[found] = product;
    } else {
      newData.push(product);
    }
    setComparisonData(newData);
    setLastlyAddedProductCompare(product);
  }

  return (
    <AddCartComparison onClick={() => addCompare()} className="btn-block">
      <PrimaryButton iconAfter={<GridIcon />} text="Add to Compare" size="xl" />
    </AddCartComparison>
  )
};

export default AddToComparison